var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center row w-100 flex font-14 text-333",class:{
    'is-strip': _vm.showStrip
  }},[_c('div',{staticClass:"selection p-l-10 p-r-10"},[_vm._t("selection")],2),_c('div',{staticClass:"leagueChsShort flex align-center"},[_c('span',{staticClass:"team-icon d-inline-block bg-center bg-size-100 bg-no-repeat",style:({
            backgroundImage: `url(${_vm.row.leagueLogo})`
          })}),_c('div',{staticClass:"flex-1"},[_c('CustomSpan',{staticClass:"font-14 text-333",attrs:{"content":_vm.filterLanguage(_vm.row, 'league', true)}})],1)]),_c('div',{staticClass:"time"},[_c('span',{staticClass:"font-12 text-333"},[_vm._v(_vm._s(_vm._f("formatTime")(_vm.row.matchTime)))])]),_c('div',{staticClass:"status"},[(_vm.row.isNumber)?[_c('span',{staticClass:"font-12",staticStyle:{"color":"#F7464E"}},[_vm._v(_vm._s(_vm.row.stateString))]),_c('span',{staticClass:"fade-in-out",staticStyle:{"color":"#F7464E"}},[_vm._v("'")])]:_c('span',{staticClass:"font-12",staticStyle:{"color":"#F7464E"}},[_vm._v(_vm._s(_vm.row.stateString))])],2),_c('div',{staticClass:"home-team flex-shrank-0 p-l-10 p-r-10 text-right"},[_c('div',{staticClass:"flex w-100 p-l-20 font-12 align-center flex-end"},[_c('div',{staticClass:"corner-box flex align-center justify-center"},[(_vm.showRed)?_c('span',{staticClass:"red-corner m-r-5 d-inline-block",class:{
                      'visibility-hidden': _vm.row.homeRed <= 0
                  }},[_vm._v(_vm._s(_vm.row.homeRed))]):_vm._e(),(_vm.showYellow)?_c('span',{staticClass:"yellow-corner m-r-5 d-inline-block",class:{
                      'visibility-hidden': _vm.row.homeYellow <= 0
                  }},[_vm._v(_vm._s(_vm.row.homeYellow))]):_vm._e()]),_c('CustomSpan',{staticClass:"team-name text-right",attrs:{"content":_vm.filterLanguage(_vm.row, 'name', true)}})],1)]),_c('div',{staticClass:"score"},[(_vm.row.showPopover)?_c('PopoverDialog',{key:_vm.row.id,attrs:{"info":_vm.row}}):_c('div',{staticClass:"score-column flex align-center justify-center font-12",class:{
                    'red': !_vm.isFuture && !_vm.isWarning && _vm.row.status !== 2
                 }},[(_vm.isFuture || _vm.isWarning)?[_c('span',[_vm._v("-")])]:[_c('span',{staticClass:"score-num"},[_vm._v(_vm._s(_vm.row.homeScore))]),_c('span',{staticClass:"line m-l-5 m-r-5"},[_vm._v("-")]),_c('span',{staticClass:"score-num"},[_vm._v(_vm._s(_vm.row.awayScore))])]],2)],1),_c('div',{staticClass:"away-team flex-shrank-0 p-l-10 p-r-10 text-left"},[_c('div',{staticClass:"flex align-center font-12"},[_c('CustomSpan',{staticClass:"team-name text-left",attrs:{"content":_vm.filterLanguage(_vm.row, 'name', false)}}),_c('div',{staticClass:"corner-box flex align-center justify-center"},[(_vm.showYellow)?_c('span',{staticClass:"yellow-corner m-l-5 d-inline-block",class:{
                  'visibility-hidden': _vm.row.awayYellow <= 0
              }},[_vm._v(_vm._s(_vm.row.awayYellow))]):_vm._e(),(_vm.showRed)?_c('span',{staticClass:"red-corner m-l-5 d-inline-block",class:{
            'visibility-hidden': _vm.row.awayRed <= 0
        }},[_vm._v(_vm._s(_vm.row.awayRed))]):_vm._e()])],1)]),_c('div',{staticClass:"half"},[(_vm.isFuture || _vm.isWarning)?_c('span',{staticClass:"font-12"},[_vm._v("-")]):_c('span',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.row.homeHalfScore)+" - "+_vm._s(_vm.row.awayHalfScore))])]),_c('div',{staticClass:"corner"},[(_vm.isFuture || _vm.isWarning)?_c('span',{staticClass:"font-12"},[_vm._v("-")]):_c('div',{staticClass:"flex font-12 align-center justify-center"},[_c('span',{staticClass:"corner-icon d-inline-block bg-no-repeat bg-center bg-size-100"}),_c('span',{staticClass:"m-l-5"},[_vm._v(_vm._s(_vm.row.homeCorner)+" - "+_vm._s(_vm.row.awayCorner))])])]),_c('div',{staticClass:"liveHost"},[_c('div',{staticClass:"flex h-100 align-center justify-center"},[_c('VirtualIcon',{nativeOn:{"click":function($event){return _vm.goToVirtual(_vm.row)}}}),_c('div',{staticClass:"m-l-20 m-r-5 host"},[(!!(_vm.row.anchor_list && _vm.row.anchor_list.length))?[_c('PopoverHost',{attrs:{"match":_vm.row,"is-dark":"","text-living":""}},[_c('span',{staticClass:"d-inline-block pointer h-100 flex align-center anchor-icon bg-center bg-no-repeat bg-size-cover",style:({
              backgroundImage: `url(${_vm.row.anchor_list[0].img})`
          })})])]:_vm._e()],2)],1)]),_c('div',{staticClass:"operations flex align-center justify-center"},[(!_vm.row.isTop)?_c('IconPng',{staticClass:"pointer",attrs:{"width":16,"height":16,"icon":"score/top"},nativeOn:{"click":function($event){return _vm.setFirst(_vm.row)}}}):_c('IconPng',{staticClass:"pointer rotate-reverse",attrs:{"width":16,"height":16,"icon":"score/cancle-top"},nativeOn:{"click":function($event){return _vm.cancleFirst(_vm.row)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }