var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"flex align-center justify-between"},[_c('div',[_c('span',{staticClass:"font-25 text-333"},[_vm._v("相关主播")]),_c('span',{staticClass:"m-l-15"},[_vm._v("共找到"+_vm._s(_vm.total)+"个结果")])]),_c('div',{staticClass:"more pointer font-14 text-333 font-400 flex align-center",class:{
                'visibility-hidden': !_vm.isAll || (!_vm.isLoading && !_vm.list.length)
            },on:{"click":_vm.changeToHost}},[_c('span',[_vm._v("查看更多")]),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"list"},[_c('ul',{staticClass:"w-100 h-100 flex align-center flex-wrap"},_vm._l((_vm.list),function(anchor){return _c('li',{key:anchor.id,staticClass:"flex m-t-10 align-center p-l-20 p-r-10 anchor"},[_c('div',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"icon p-relative",class:{
                            'is-living': anchor.live_status === 2
                     }},[_c('img',{staticClass:"w-100 h-100 border-radius-50",attrs:{"data-src":anchor.logo,"data-error":_vm.errorUserLogo,"alt":""}})]),_c('div',{staticClass:"info flex-1 m-l-15"},[_c('custom-span',{staticClass:"font-16 w-100 font-400 text-333",attrs:{"content":anchor.anchor_name}}),_c('custom-span',{staticClass:"font-12 w-100 m-t-5 font-400 text-999",attrs:{"content":anchor.sign}}),_c('span',{staticClass:"follow-btn m-t-5 pointer text-center font-12 d-inline-block",class:{
                            'is-active': anchor.is_follow === 1
                        },on:{"click":function($event){return _vm.subscribe(anchor)}}},[_vm._v(" "+_vm._s(anchor.is_follow === 0 ? '关注' : '取消关注')+" ")])],1)])}),0),(!_vm.isLoading && !_vm.list.length)?_c('no-data',[_c('span',{attrs:{"calss":"font-14 text-333 font-400 m-t-20"}},[_vm._v("暂无相关内容")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }