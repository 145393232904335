var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{attrs:{"width":"400","placement":"right","popper-class":"score-row-popper","trigger":"hover"},on:{"hide":function($event){_vm.show=false},"show":_vm.getLineup}},[_c('div',{staticClass:"content font-12"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"flex justify-between align-center row header-row"},[_c('div',{staticClass:"left text-center"},[_c('CustomSpan',{attrs:{"content":_vm.realTimeInfo.homeChs}})],1),_c('span',{staticClass:"middle font-16 text-white text-center"},[_vm._v(_vm._s(_vm.realTimeInfo.homeScore)+" - "+_vm._s(_vm.realTimeInfo.awayScore))]),_c('div',{staticClass:"right text-center"},[_c('CustomSpan',{attrs:{"content":_vm.realTimeInfo.awayChs}})],1)]),_c('div',{staticClass:"loading-box"},[[_c('div',{staticClass:"row text-center bottom-border"},[_c('span',[_vm._v("比赛结束")])]),_c('div',{staticClass:"exchange-and-shot overflow-y-auto show-scrollbar"},[_vm._l((_vm.events),function(event){return [_c('div',{key:event.id,staticClass:"row flex justify-between align-center bottom-border"},[_c('div',{staticClass:"left text-right flex align-center flex-end",class:{
                      'visibility-hidden': !event.isHome
                    }},[_c('CustomSpan',{staticClass:"name flex-1",attrs:{"content":event.nameChs}}),(event.icon)?_c('span',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(require(`../../../assets/images/live-room/statics/${event.icon}.png`)),expression:"require(`../../../assets/images/live-room/statics/${event.icon}.png`)",arg:"background-image"}],staticClass:"icon d-inline-block m-l-5 m-r-5 bg-center bg-no-repeat bg-size-100"}):_vm._e()],1),_c('div',{staticClass:"middle text-center"},[_vm._v(_vm._s(event.time)+"'")]),_c('div',{staticClass:"right text-left flex align-center",class:{
                      'visibility-hidden': event.isHome
                    }},[(event.icon)?_c('span',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(require(`../../../assets/images/live-room/statics/${event.icon}.png`)),expression:"require(`../../../assets/images/live-room/statics/${event.icon}.png`)",arg:"background-image"}],staticClass:"icon d-inline-block m-l-5 m-r-5 bg-center bg-no-repeat bg-size-100"}):_vm._e(),_c('CustomSpan',{staticClass:"name flex-1",attrs:{"content":event.nameChs}})],1)])]})],2),_c('div',{staticClass:"row text-center bottom-border"},[_c('span',[_vm._v("比赛开始")])]),_c('div',{staticClass:"statics"},[_vm._l((_vm.statics),function(item){return [(!item.hide && _vm.showIds.includes(item.key))?_c('div',{key:item.event,staticClass:"row flex align-center justify-center"},[_c('div',{staticClass:"left cards flex align-center flex-end"},[_c('span',{staticClass:"team-value m-r-5 d-inline-block font-12 font-medium font-500"},[_vm._v(_vm._s(item.home_team))]),_c('el-progress',{staticClass:"is-reverse",attrs:{"color":"#FF5B5D","percentage":item.homeVal,"show-text":false}})],1),_c('div',{staticClass:"middle text-center justify-center text-333 flex align-center"},[_c('span',{staticClass:"font-12 event-name font-regular font-400"},[_vm._v(_vm._s(item.event))])]),_c('div',{staticClass:"right cards flex align-center"},[_c('el-progress',{attrs:{"percentage":item.awayVal,"show-text":false}}),_c('span',{staticClass:"team-value m-l-5 d-inline-block font-12 font-medium font-500"},[_vm._v(_vm._s(item.away_team))])],1)]):_vm._e()]})],2)]],2)])]),_c('div',{staticClass:"score-column flex align-center justify-center font-14",class:{
          'red': _vm.info.status !== 2
       },attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"score-num text-right"},[_vm._v(_vm._s(_vm.info.homeScore))]),_c('span',{staticClass:"line m-l-5 m-r-5"},[_vm._v("-")]),_c('span',{staticClass:"score-num text-left"},[_vm._v(_vm._s(_vm.info.awayScore))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }